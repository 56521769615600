import React from 'react';
import styled from 'styled-components';
import H3 from './../H3';
import P from './../P';
import checkmark from './../checkmark.png';

const Block = styled.div`
  max-width: 1146px;
  margin: 0 auto;
  background: white;
  margin: 0 10px 28px;

  @media (min-width: 640px) {
    margin: 0 20px 28px;
  }
  @media (min-width: 970px) {
    > *:not(:first-child) {
      max-width: 970px;
      margin: 0 auto;
    }
  }
  @media (min-width: 1146px) {
    margin: 0 auto 28px;
  }

`;
const Hero = styled.div`
  background-image: url('${props => props.bg}');
  background-size: cover;
  background-position: 50%;
  width: 100%;
  padding-top: 39%;
  @media (min-width: 960px) {
  padding-top: 36%;

  }
`;

const Content = styled.div`
  text-align: center;
  padding: 48px 10px;
`

const Copy = styled(P)`
  margin-bottom: 44px;

  @media (min-width: 960px) {
    margin-bottom: 72px;
  }
`

const H4 = styled.h4`
  color: #e20074;
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 44px;
`;

const Benefits = styled.div`
  text-align: left;

  ul {
    list-style: none;
    padding-left: 0;
    @media (min-width: 970px ) {
      column-count: 2;
      column-gap: 32px;
    }
  }
  
  li {
    color: #4b4b4b;
    font-size: 20px;
    margin-bottom: 1em;
    position: relative;
    background: url(${checkmark}) no-repeat;
    background-position: 0px 0px;
    padding-left: 60px;
    padding-top: 9px;
    
    -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
               break-inside: avoid;
   
  }

  li strong {
    display: block;
    font-weight: 900;
    color: #e20074;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
`

export default ({content}) => (
  <Block id={content.internalName}>
    <Hero bg={content.image.url} />
    <Content>
    <H3>{content.title}</H3>
    <Copy dangerouslySetInnerHTML={{__html:content.copy}}/>
    <H4>Benefits</H4>

    <Benefits dangerouslySetInnerHTML={{__html: content.benefitsNode.childMarkdownRemark.html}} />
    
    </Content>
  </Block>
);
