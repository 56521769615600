import React from 'react';
import styled from 'styled-components';


export default styled.h3`
  color: ${props => props.color || '#4b4b4b'};
  font-size: 26px;
  margin-bottom: 1.1em;
  font-weight: 400;
  text-transform: uppercase;
  @media (min-width: 640px) {
    font-size: 30px;
  }
`;

