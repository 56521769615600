import React from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import Hero from "./../components/Hero";
import Content from "./../components/Content";
import TextBlock from "./../components/TextBlock";
import ISPNavigation from "../components/ISP/ISPNavigation";
import FlightBlock from "../components/ISP/FlightBlock";
import Performance from "../components/Home/Performance";
import Layout from "./../components/Layout";

const Page = styled.div``;

export default ({ data: { datoCmsIsp: content, home }, location }) => (
  <Layout location={location}>
    <Page>
      <Hero src={content.headerImage.url} />

      <Content bg="white">
        <TextBlock title={content.pageTitle} copy={content.pageIntro} />
      </Content>
      <Content bg="#e20074" style={{ paddingBottom: 48 }}>
        <TextBlock
          color="magenta"
          maxWidth="1140px"
          title={content.ispIntroTitle}
          copy={content.ispIntroCopy}
        />
        <ISPNavigation />
        {content.flightBlocks
          ? content.flightBlocks.map((b, k) => (
              <FlightBlock content={b} key={k} />
            ))
          : null}
      </Content>
      {/* <Performance */}
      {/*   title={home.airlinesBlock[0].title} */}
      {/*   subline={home.airlinesBlock[0].subline} */}
      {/*   intro={home.airlinesBlock[0].intro} */}
      {/*   airlines={home.airlinesBlock[0].airlinesList} */}
      {/* /> */}
      <Content bg="#ededed">
        <TextBlock title={content.outroTitle} copy={content.outroCopy} />
      </Content>
    </Page>
  </Layout>
);

export const query = graphql`
  query ISPQuery {
    datoCmsIsp {
      headerImage {
        url
      }
      pageTitle
      pageIntro
      ispIntroTitle
      ispIntroCopy
      flightBlocks {
        internalName
        title
        copy
        image {
          url
        }
        benefitsNode {
          childMarkdownRemark {
            html
          }
        }
      }
      outroTitle
      outroCopy
    }
    home: datoCmsHomePage {
      airlinesBlock {
        title
        subline
        intro
        airlinesList {
          url
          alt
          width
          height
        }
      }
    }
  }
`;
