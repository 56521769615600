import React from 'react';
import styled from 'styled-components';
import P from '../typo/P'
import H2 from '../typo/H2'
import H3 from '../typo/H3'



const Performance = ({title, subline, intro, airlines}) => (
  <section className="performance-block">
    <div className="container">
      <H2>{title}</H2>
      <H3 dangerouslySetInnerHTML={{__html: subline}}/>
      <P>{intro}</P>
      <ul className="airlines-list">
        {airlines.map((a, k) => (
          <li key={k}>
            <a href="#">
              <img
                src={a.url}
                width={a.width}
                height={a.height}
                alt={a.alt}
                title={a.title}
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export default Performance;
