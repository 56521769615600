import React from 'react';
import styled from 'styled-components';

export default styled.div`
  color: ${props => props.color || '#4b4b4b'};
  font-size: 20px;
  line-height: 1.28;
  @media (min-width: 640px) {
    font-size: 25px;
  }
  @media (min-width: 960px) {
    font-size: 20px;
  }
`