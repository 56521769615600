import React from 'react';
import styled from 'styled-components';


export default styled.h2`
  color: ${props => props.color};
  font-size: 32px;
  margin-bottom: 1.1em;
  @media (min-width: 640px) {
    font-size: 40px;
  }
`;

