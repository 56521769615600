import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';

const List = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding-left: 0;
  padding-bottom: 45px;
  max-width: 1146px;
  width: 296px;

  @media (min-width: 960px) {
    display: flex;
    width: 100%;
  }
`;

const ListItem = styled.li`
  display: inline-block;
  margin-bottom: 35px;
  @media (min-width: 960px) {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
  }
`;

const IconLink = styled(Link)`
  height: 42px;
  line-height: 42px;
  font-size: 41px;
  text-decoration: none;
  color: white;
  padding-left: 54px;
  font-weight: 900;
  text-transform: uppercase;

  @media (min-width: 960px) {
    font-size: 31px;
  }

  &:hover,
  &:active {
    color: #4b4b4b;
    i {
      border-color: #4b4b4b;
    }
    svg path {
      fill: #4b4b4b;
    }
  }
`;

const Icon = styled.i`
  margin-left: -54px;
  position: relative;
  border-width: 3px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-radius: 50%;
  position: absolute;
  width: 42px;
  height: 42px;

  svg,
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const House = styled.svg`
  margin-top: -2px;
`;
const Home = styled.svg`
  margin-top: 4px;
`;

export default ({}) => (
  <List>
    <ListItem>
      <IconLink to="/isp-story#preflight">
        <Icon>
          <HouseIcon />
        </Icon>
        Pre-Flight
      </IconLink>
    </ListItem>
    <ListItem>
      <IconLink to="/isp-story#inflight">
        <Icon>
          <PlaneIcon />
        </Icon>
        In-Flight
      </IconLink>
    </ListItem>
    <ListItem>
      <IconLink to="/isp-story#postflight">
        <Icon>
          <HomeIcon />
        </Icon>
        Post-Flight
      </IconLink>
    </ListItem>
  </List>
);

const HouseIcon = () => (
  <House xmlns="http://www.w3.org/2000/svg" width="27px" height="26px">
    <path
      fillRule="evenodd"
      fill="rgb(255, 255, 255)"
      d="M13.756,0.333 L0.179,12.173 L3.799,12.173 L3.799,25.834 L11.041,25.834 L11.041,17.637 L16.472,17.637 L16.472,25.834 L23.713,25.834 L23.713,12.173 L26.429,12.173 L13.756,0.333 Z"
    />
  </House>
);
const PlaneIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="27px">
    <path
      fillRule="evenodd"
      fill="rgb(255, 255, 255)"
      d="M25.747,0.688 C25.747,0.688 22.596,0.434 21.204,1.595 C19.811,2.755 14.843,7.941 14.843,7.941 L4.847,6.128 C4.847,6.128 1.044,5.483 1.212,7.034 C1.381,8.586 11.208,11.568 11.208,11.568 L5.756,18.822 C5.756,18.822 0.438,18.009 0.304,18.822 C0.078,20.186 3.558,20.482 4.847,21.541 C6.576,22.963 6.301,26.698 7.573,26.075 C8.845,25.452 7.573,20.635 7.573,20.635 L14.843,14.288 C14.843,14.288 17.553,26.552 19.386,26.075 C21.781,25.452 18.478,11.568 18.478,11.568 L24.838,5.221 C24.838,5.221 26.029,3.339 25.747,0.688 "
    />
  </svg>
);
const HomeIcon = () => (
  <Home xmlns="http://www.w3.org/2000/svg" height="37px" viewBox="0 0 100 125">
    <g>
      <path
        fill="rgb(255, 255, 255)"
        d="M81.365,36.962c0,6.027-1.6,11.439-4.552,15.99L50,94.403L23.186,53.567c-2.46-3.813-4.551-8.733-4.551-16.605   c0-17.22,14.021-31.365,31.365-31.365C67.343,5.597,81.365,19.742,81.365,36.962z M36.962,34.502   c0,7.011,6.026,12.669,13.038,12.669c7.011,0,12.669-5.658,12.669-12.669S57.011,21.464,50,21.464   C42.988,21.464,36.962,27.491,36.962,34.502z"
      />
    </g>
  </Home>
);
