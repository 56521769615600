import React from "react";
import styled from "styled-components";

const Hero = styled.div`
  width: 100%;
  height: 58vw;
  background-size: cover;
  background-position: 50%;
  border-top: 1px solid #e20074;

  @media (min-width: 768px) {
    height: 43vw;
  }
  @media (min-width: 960px) {
    height: 29vw;
  }
  @media (min-width: 1980px) {
    height: 19vw;
  }
`;

export default ({ src }) => <Hero style={{ backgroundImage: `url(${src})` }} />;
