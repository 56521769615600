import React from 'react';
import styled from 'styled-components';
import H2 from './H2';
import H3 from './H3';
import P from './P';

const TextBlock = styled.div`
  max-width: ${props => props.maxWidth};
  text-align: center;
  margin: 0 auto;
  padding: 48px 10px;

  @media (min-width: 640px) {
    padding: 58px 20px 48px;
    &:last-child {
      padding: 58px 20px;
    }
  }
  @media (min-width: 960px) {
    padding: 74px 20px 48px;
    &:last-child {
      padding: 74px 20px;
    }
  }
  @media (min-width: 1200px) {
    padding: 112px 20px 48px;
    &:last-child {
      padding: 112px 20px;
    }
  }
`;

export default ({title, copy, subline, className, maxWidth = '1190px', color = 'regular'}) => (
  <TextBlock maxWidth={maxWidth} className={className}>
    <H2 color={color === 'regular' ? '#e20074' : 'white'}>{title}</H2>
    <H3 color={color === 'regular' ? '#e20074' : 'white'}>{subline}</H3>
    <P
      color={color === 'regular' ? '#4b4b4b' : 'white'}
      dangerouslySetInnerHTML={{__html: copy}}
    />
  </TextBlock>
);
